import React from 'react'
import axios from 'axios'
import { useLocation } from '@reach/router'

// Components
import PageHelmet from '../components/pageHelmet'
import SubpageText from '../components/subpage/subpageText'
import ReadMore from '../components/readMore'
import { FatReadMoreType } from '../components/sections/fatBurning'

// Styles
import 'styles/global.scss'

type FatBurningType = {
  serverData: {
    cmsData: {
      data: {
        attributes: {
          ReadMore: FatReadMoreType[]
        }
      }
    }
  }
}

const FatBurningPage: React.FC<FatBurningType> = ({ serverData }) => {
  const location = useLocation()
  const { cmsData } = serverData

  const ReadMoreObject = cmsData.data?.attributes.ReadMore.map((obj, index) => {
    const param = new URLSearchParams(location.search).get('read') || ''
    const readValue = param.replace(/-/g, ' ')
    const isActive = param ? readValue === obj.Title : false

    return <ReadMore key={index} readActive={isActive} title={obj?.Title} description={obj?.Description} descriptionImage={obj?.Image.Image.data != null ? `${process.env.GATSBY_PUBLIC_STRAPI_URL}${obj?.Image.Image.data?.attributes.url}` : undefined} imageText={obj?.Image.Text} />
  }) || [<div key={0}></div>]

  return (
    <PageHelmet title="Sailor's Food - Fat Burning">
      <SubpageText page="fat-burning">{ReadMoreObject}</SubpageText>
    </PageHelmet>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export async function getServerData(context) {
  const data = await axios.get(`${process.env.GATSBY_STRAPI_URL}/api/fat-burning-subpage?populate=deep`)

  return {
    status: data.status,
    props: {
      cmsData: data.data ?? null,
    },
    headers: {},
  }
}

export default FatBurningPage

import React, { useEffect, useRef, useState } from 'react'

import 'styles/components/readMore.scss'
import 'styles/components/subpage/subpageText.scss'

type ReadMoreType = {
  title: string
  description: string
  descriptionImage?: string
  imageText?: string
  readActive?: boolean
  imageBottom?: boolean
}

const ReadMore: React.FC<ReadMoreType> = ({ title, description, descriptionImage, imageText, readActive, imageBottom }) => {
  const [active, setActive] = useState<boolean>(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const descriptionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (readActive && descriptionRef) {
      ToggleReadMore()
    }
  }, [descriptionRef])

  const ToggleReadMore = (): void => {
    if (descriptionRef && descriptionRef.current) {
      const ref = descriptionRef.current

      if (ref.style.maxHeight) {
        // @ts-ignore - works fine with null lol
        ref.style.maxHeight = null
      } else {
        ref.style.maxHeight = `${ref.scrollHeight}px`

        setTimeout(() => {
          if (containerRef && containerRef.current) {
            containerRef.current.scrollIntoView({
              behavior: 'smooth',
            })
          }
        }, 250)
      }

      setActive(!active)
    }
  }

  const RenderImage = (): JSX.Element => {
    if (descriptionImage) {
      const className = `description-image-container${imageBottom ? ' bottom-image' : ''}`

      return (
        <div className={className}>
          <img className="description-image" src={descriptionImage!} alt="descriptionImage" />
          {imageText ? <p className="description-image-text">{imageText}</p> : <React.Fragment />}
        </div>
      )
    }

    return <React.Fragment />
  }

  const descriptionString = `${description.slice(0, 400)}...`
  const previewDescriptionClass = `read-more-description preview${active ? ' preview-active' : ''}`
  const descriptionClass = `read-more-description`

  return (
    <div className="description-container read-more-container" ref={containerRef}>
      <div className="description-wrapper">
        <p className="description-header">{title}</p>
        <div className={previewDescriptionClass}>
          <p
            className="description-text preview"
            dangerouslySetInnerHTML={{
              __html: descriptionString,
            }}></p>
        </div>
        <div className={descriptionClass} ref={descriptionRef}>
          <RenderImage />
          <p
            className="description-text"
            dangerouslySetInnerHTML={{
              __html: description,
            }}></p>
        </div>
        <div className="read-more-button-container">
          <button onClick={ToggleReadMore}>{active ? 'Read Less' : 'Read More'}</button>
        </div>
      </div>
    </div>
  )
}

export default ReadMore
